const initial = {
  selectedProduct: null,
};
export const productReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "SELECTED_PRODUCT":
      return { ...state, selectedProduct: action.payload };

    default: {
      return state;
    }
  }
};
