import React, { useState } from "react";
import { Image, Row, Col, Typography, Card, Input, Button, Form } from "antd";
import { useForm } from "@refinedev/antd";
import { MapPin, Mail, Phone } from "react-feather";
import { useTranslate, useGetIdentity } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6/legacy";

import "./style.css";
const { Text, Title } = Typography;
type IUser = {
  id: number;
  name: string;
  avatar: string;
};
export const ContactUs = () => {
  const t = useTranslate();
  const { data: user } = useGetIdentity<IUser>();
  const { formProps } = useForm({
    resource: "messages",
    redirect: false,
  });
  const history = routerProvider.useHistory();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <div style={{ margin: "0px", padding: "0px" }}>
        <div>
          <Image
            src="./images/bora/07.jpg"
            preview={false}
            width={"100%"}
            height={750}
          />
        </div>
        <div className="contact_mask">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            justify={"start"}
            style={{ margin: 0, padding: 0, alignItems: "center" }}
          >
            <Col className="gutter-row" span={14}>
              {" "}
              <div className="contact__mask__content">
                <Text className="contact_title_mask">Get in touch</Text>
                <h2 className="contact_prag_mask">
                  Want to get in touch? We'd love to hear from you. Here's how
                  you can reach us...
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row gutter={16} style={{ marginTop: -80, marginBottom: 100 }}>
        <Col xs={20} sm={20} md={12} lg={12} xl={12}>
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={22} lg={22} xl={22}>
              <Card
                style={{
                  borderTopLeftRadius: 70,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 70,
                  borderBottomRightRadius: 0,
                  left: 20,
                  height: 420,
                }}
                className="contact__info"
              >
                <Row justify={"center"} style={{ marginTop: 20 }}>
                  <Title>Contact Information</Title>
                </Row>
                <Row justify={"center"} style={{ marginTop: 50 }}>
                  <Col span={2}>
                    <MapPin size={35} style={{ marginTop: 25 }} />
                  </Col>
                  <Col span={12}>
                    <div style={{ marginLeft: 20 }}>
                      <h2>Address</h2>
                      <h4>Ulmenweg 32 52428 Julich, Germany</h4>
                    </div>
                  </Col>
                </Row>
                <Row justify={"center"} style={{ marginTop: 20 }}>
                  <Col span={2}>
                    <Phone size={35} style={{ marginTop: 25 }} />
                  </Col>
                  <Col span={12}>
                    <div style={{ marginLeft: 15 }}>
                      <h2>Phone</h2>
                      <h4>+491783059595</h4>
                    </div>
                  </Col>
                </Row>
                <Row justify={"center"} style={{ marginTop: 20 }}>
                  <Col span={2}>
                    <Mail size={35} style={{ marginTop: 25 }} />
                  </Col>
                  <Col span={12}>
                    <div style={{ marginLeft: 15 }}>
                      <h2>Email</h2>
                      <h4>info@medbora.de</h4>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={20} sm={20} md={12} lg={12} xl={12}>
          <Row justify={"center"}>
            <Col xs={24} sm={24} md={22} lg={22} xl={22}>
              <Card
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 70,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 70,
                  right: 20,
                  height: 420,
                }}
                className="contact__message"
              >
                <Row justify={"center"} style={{ marginTop: 20 }}>
                  <Title>Send us message</Title>
                </Row>
                <Form {...formProps} layout="vertical" style={{ padding: 20 }}>
                  <Form.Item name={"email"}>
                    <Input
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item name={"subject"}>
                    <Input
                      placeholder="Subject"
                      required
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item name={"message"}>
                    <Input.TextArea
                      placeholder="Message"
                      rows={4}
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Item>
                  <Row justify={"center"} style={{ marginTop: 20 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                          height: 40,
                          fontSize: 20,
                          backgroundColor: "#088d95",
                        }}
                        onClick={() =>
                          formProps.onFinish?.({
                            email,
                            subject,
                            message,
                          })
                        }
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {/* </Create> */}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {user && (
        <Row>
          <Button
            type="primary"
            style={{ width: "100%", height: 40, fontSize: 20 }}
            onClick={() => {
              history.push("/messages");
            }}
          >
            Show Messages
          </Button>
        </Row>
      )}
    </>
  );
};
