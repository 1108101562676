import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  useNotificationProvider,
  ThemedLayoutV2,
  ErrorComponent,
} from "@refinedev/antd";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { App as AntdApp } from "antd";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Footer, Header } from "components";
import {
  Home,
  ContactUs,
  Products,
  Login,
  ListMessages,
  CreateCategory,
  EditCategory,
  ProductsByCategory,
  ShowProduct,
  CreateProduct,
} from "./pages";
import { authProvider } from "./authProvider";
import "@refinedev/antd/dist/reset.css";
function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <DevtoolsProvider>
              <Refine
                dataProvider={dataProvider("http://176.126.87.249/api")}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                resources={[
                  {
                    name: "home",
                    list: "/",
                  },
                  {
                    name: "contact-us",
                    list: "/contact-us",
                  },
                  {
                    name: "messages",
                    list: "/messages",
                  },
                  {
                    name: "products",
                    list: "/products",
                    show: "/products/show/:_id",
                    create: "/products/create",
                  },
                  {
                    name: "categories",
                    create: "/categories/create",
                    edit: "/categories/edit/_id",
                  },
                  {
                    name: "categoryProducts",
                    list: "/products/by-category",
                  },
                  // {
                  //   name: "companies",

                  //   list: "/companies",
                  //   show: "/companies/show/:id",
                  //   edit: "/companies/edit/:id",
                  //   create: "/companies/create",
                  // },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                {/* <ThemedLayoutV2
                  Header={Header}
                  initialSiderCollapsed={true}
                  Title={() => {
                    return <h3>BORA</h3>;
                  }}
                  Footer={() => {
                    return <h3>Contact mr </h3>;
                  }}
                  Sider={() => {
                    return <></>;
                  }}
                >
                  <Routes>
                    
                    <Route path="*" element={<ErrorComponent />} />
                  </Routes>
                </ThemedLayoutV2> */}

                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2
                          Header={() => <Header isSticky={true} />}
                          Sider={() => {
                            return <></>;
                          }}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route path="/messages">
                      <Route index element={<ListMessages />} />
                    </Route>

                    <Route path="/categories">
                      <Route path="create" element={<CreateCategory />} />
                      <Route path="edit" element={<EditCategory />} />
                    </Route>

                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <ThemedLayoutV2
                        Header={() => <Header isSticky={true} />}
                        initialSiderCollapsed={true}
                        Title={() => {
                          return <h3>BORA</h3>;
                        }}
                        Footer={() => <Footer />}
                        Sider={() => {
                          return <></>;
                        }}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    }
                  >
                    <Route index path="/" element={<Home />} />
                    <Route path="/contact-us">
                      <Route index element={<ContactUs />} />
                    </Route>
                    <Route path="/products/by-category">
                      <Route index element={<ProductsByCategory />} />
                    </Route>
                    <Route path="/products">
                      <Route index element={<Products />} />
                      <Route path="create" element={<CreateProduct />} />
                      <Route path="show/:_id" element={<ShowProduct />} />
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                  </Route>
                </Routes>
              </Refine>
            </DevtoolsProvider>
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
