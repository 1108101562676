import React from "react";
import { Col, Row, Typography } from "antd";
import { FacebookFilled } from "@ant-design/icons";
const { Text, Title, Paragraph } = Typography;

export const Footer = () => {
  return (
    <footer
      style={{
        marginTop: 50,
        padding: "20px 50px",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Row justify={"center"}>
        <Col xs={24} sm={20} md={18} lg={14}>
          <Title level={3} style={{ color: "#fff" }}>
            BORA Dental Implants
          </Title>
          <Paragraph style={{ fontSize: 14, color: "#ccc" }}>
            © {new Date().getFullYear()} BORA. All rights reserved.
            {/* | Privacy Policy | Terms of Service */}
          </Paragraph>
          <Paragraph style={{ fontSize: 14, color: "#ccc" }}>
            info@medbora.de | +491783059595 |{" "}
            <a
              href="https://www.facebook.com/profile.php?id=61565212289241"
              target="_blank"
            >
              <FacebookFilled size={30} />
            </a>
          </Paragraph>
        </Col>
      </Row>
    </footer>
  );
};
