import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
  useTranslate,
} from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Switch,
  Typography,
  theme,
  Menu,
  Image,
} from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorModeContext } from "../../contexts/color-mode";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { authProvider } from "authProvider";

const { Text } = Typography;
const { useToken } = theme;
const Link = routerProvider.Link;

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky = true,
}) => {
  const { token } = useToken();
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<IUser>();
  const { mode, setMode } = useContext(ColorModeContext);
  const t = useTranslate();
  const [menu, setMenu] = useState(false);
  const currentLocale = locale();

  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => {
        changeLanguage(lang);
        console.log("lang", lang);
      },
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar
            size={16}
            src={`/images/flags/${lang}${lang === "ar" ? ".png" : ".svg"}`}
          />
        </span>
      ),
      label: lang === "en" ? "English" : "Arabic",
    }));

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 0px",
    height: "100px",
    zIndex: 1,
  };

  if (isSticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  const handleLogout = () => {
    localStorage.removeItem("refine-auth");
  };

  return (
    <AntdLayout.Header style={headerStyles}>
      <div className="logo">
        <Image
          src="../../images/bora/logo.png"
          width={150}
          height={100}
          preview={false}
        />
      </div>
      <Menu
        mode="horizontal"
        style={{
          flex: 1,
          minWidth: 0,
          border: "none",
          fontSize: 20,
          height: 100,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Menu.Item key="catalogue">
          <Button
            type="text"
            icon={<DownloadOutlined />}
            href="/images/pdf/Cateloge.pdf"
            download="Catalogue.pdf"
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              borderColor: token.colorPrimary,
            }}
          >
            Catalog
          </Button>
        </Menu.Item>
        <Menu.Item key={1}>
          <Link to={"/"}>{t("home.title")}</Link>
        </Menu.Item>
        <Menu.Item key={2}>
          <Link to={"/products"}>{t("home.products")}</Link>
        </Menu.Item>
        <Menu.Item key={3}>
          <Link to={"/contact-us"}>{t("home.ContactUs")}</Link>
        </Menu.Item>
        {/* <Menu.Item key={4}>
          <Dropdown
            menu={{
              items: menuItems,
              selectedKeys: currentLocale ? [currentLocale] : [],
            }}
          >
            <Button type="text">
              <Space>
                <Avatar
                  size={16}
                  src={`/images/flags/${currentLocale}${
                    currentLocale === "ar" ? ".png" : ".svg"
                  }`}
                />
                {currentLocale === "en" ? "English" : "Arabic"}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Menu.Item> */}
        <Menu.Item key={5}>
          <Switch
            checkedChildren="🌛"
            unCheckedChildren="🔆"
            onChange={() => setMode(mode === "light" ? "dark" : "light")}
            defaultChecked={mode === "dark"}
          />
        </Menu.Item>
        <Menu.Item key={6}>
          {/* {user?.name && (
            <Text strong style={{ marginRight: 10 }}>
              {user.name}
            </Text>
          )} */}
          {user?.name && (
            <Link to={"/login"} onClick={handleLogout}>
              Logout
            </Link>
          )}
        </Menu.Item>
      </Menu>
    </AntdLayout.Header>
  );
};
