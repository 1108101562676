const initial = {
  selectedCategory: null,
};
export const categoryReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "SELECTED_CATEGORY":
      return { ...state, selectedCategory: action.payload };

    default: {
      return state;
    }
  }
};
