import { AuthPage } from "@refinedev/antd";

export const Login = () => {
  return (
    <AuthPage
      type="login"
      title={"BORA"}
      registerLink={false}
      rememberMe={false}
      forgotPasswordLink={false}
      // formProps={{
      //   initialValues: { email: "admin@medbora.de", password: "DEboraMED!" },
      // }}
    />
  );
};
