import { combineReducers } from "redux";
import { categoryReducer } from "../pages/Category/redux/categoryReducer";
import { productReducer } from "../pages/Products/redux/productReducer";

const reducer = combineReducers({
  categories: categoryReducer,
  products: productReducer,
});

export default reducer;
