import React, { useState } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select } from "antd";

export const CreateProduct: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<any>({
    resource: "products",
  });

  const [image, setImage] = useState<any>({});

  const { selectProps: categoriesProps, queryResult } = useSelect({
    resource: "categories",
    optionLabel: "name",
    optionValue: "_id",
  });

  console.log("queryResult", queryResult);

  return (
    <Row gutter={16} justify={"center"} style={{ marginTop: 20 }}>
      <Col xs={24} sm={24} md={22} lg={22} xl={22}>
        <Create saveButtonProps={saveButtonProps} title={"Create Product"}>
          <Form
            encType="multipart/form-data"
            {...formProps}
            style={{ margin: 15 }}
            layout="vertical"
            onFinish={(values: any) => {
              let formData = new FormData();
              formData.append("name", values.name);
              formData.append("description", values.description);
              formData.append("categoryId", values.categoryId);
              formData.append("image", image);
              return formProps.onFinish?.(formData);
            }}
          >
            <Form.Item
              label={"Name"}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Description"}
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea cols={24} rows={5} />
            </Form.Item>
            <Form.Item
              label={"Category"}
              name="categoryId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                allowClear={false}
                showSearch={false}
                options={queryResult?.data?.data.map((c: any) => ({
                  label: c.name,
                  value: c._id,
                }))}
                {...categoriesProps}
              />
            </Form.Item>

            <Form.Item
              label={"Image"}
              name="image"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <input
                type="file"
                onChange={(e: any) => {
                  setImage(e.target.files[0]);
                }}
              />
            </Form.Item>
          </Form>
        </Create>
      </Col>
    </Row>
  );
};
