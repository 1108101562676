import React from "react";
import { List, useTable } from "@refinedev/antd";
import { Button, Space, Table } from "antd";
import { DeleteButton, TextField, BooleanField } from "@refinedev/antd";
import { useDelete, useTranslate } from "@refinedev/core";
import { DeleteFilled } from "@ant-design/icons";

interface IMessgaes {
  email: string;
  subject: string;
  message: string;
  sentAt: string;
}

export const ListMessages = () => {
  const { mutate: mutateDelete } = useDelete();
  const { tableProps } = useTable<IMessgaes>({
    resource: "messages",
  });

  console.log("tableProps", tableProps);

  return (
    <List title={""}>
      <Table {...tableProps} rowKey="id" style={{ margin: 50 }}>
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column dataIndex="subject" title="Subject" />
        <Table.Column
          dataIndex="sentAt"
          title="Sent At"
          render={(value) => (
            <TextField value={value.slice(0, 10) + " " + value.slice(11, 19)} />
          )}
        />
        <Table.Column dataIndex="message" title="Message" />

        <Table.Column
          dataIndex="actions"
          title="Actions"
          render={(_: any, record: any) => (
            <Space>
              <Button
                size="small"
                icon={<DeleteFilled />}
                onClick={() => {
                  mutateDelete({
                    resource: "messages",
                    id: record._id,
                  });
                }}
              ></Button>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
