import React from "react";
import { Row, Col, Image, Button, Card } from "antd";
import {
  useTranslate,
  useCustom,
  useDelete,
  useGetIdentity,
} from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { useDispatch } from "react-redux";

import "./style.css";
import { DeleteFilled } from "@ant-design/icons";

const { Meta } = Card;
type IUser = {
  id: number;
  name: string;
  avatar: string;
};
export const Products = () => {
  const { mutate: mutateDelete } = useDelete();
  const { data: user } = useGetIdentity<IUser>();

  const { data, refetch }: any = useCustom({
    url: `http://176.126.87.249/api/products`,
    method: "get",
    config: {
      headers: {
        "Content-Type": "image/jpeg",
      },
    },
  });
  const history = routerProvider.useHistory();
  const dispatch = useDispatch();
  return (
    <div>
      <Row gutter={16} justify={"center"}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="header">
            <Row gutter={16} justify={"center"}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div style={{ marginLeft: 50, marginTop: 50, color: "white" }}>
                  <h1
                    className="header__title"
                    style={{ fontWeight: 900, fontSize: "35px" }}
                  >
                    Explore our range and discover why BORA GbR is synonymous
                    with excellence in dental implants.
                  </h1>
                  <h3 className="header__title__content">
                    Our product line offers advanced solutions that cater to the
                    diverse needs of modern implantology. From high-performance
                    implants to cutting-edge components, every product is
                    meticulously engineered to provide reliable results and
                    long-term success.
                  </h3>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Image
                  src="./images/bora/prod2.png"
                  width={"80%"}
                  height={550}
                  style={{ marginTop: -50 }}
                  preview={false}
                  className="header__img"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {user && (
        <Row
          gutter={[24, 24]}
          justify={"space-around"}
          align={"top"}
          style={{ marginTop: 90 }}
        >
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={() => {
              history.push("/products/create");
            }}
          >
            Create New Product
          </Button>
        </Row>
      )}

      <Row
        gutter={[24, 24]}
        justify={"space-around"}
        align={"top"}
        style={{ marginTop: 90 }}
      >
        {data?.data?.map((prod: any) => {
          return (
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card
                hoverable
                className="product__card"
                style={{
                  width: "90%",
                  marginBottom: 30,
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 12,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                }}
                cover={
                  <Image
                    src={`http://176.126.87.249/uploads/${prod.image}`}
                    preview={false}
                    style={{ objectFit: "cover" }}
                    height={200}
                    className="card-image"
                    width={150}
                    onClick={() => {
                      dispatch({
                        type: "SELECTED_PRODUCT",
                        payload: prod,
                      });
                      history.push(`/products/show/${prod._id}`);
                    }}
                  />
                }
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "translateY(-20px)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 20px rgba(0, 0, 0, 0.15)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "translateY(0)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 12px rgba(0, 0, 0, 0.1)";
                }}
              >
                <Meta title={prod.name} description={prod.description} />
                {user && (
                  <div style={{ marginTop: 16 }}>
                    {/* <Button
                    size="small"
                    icon={<EditFilled style={{ color: "#4096ff" }} />}
                    onClick={() => {
                      dispatch({
                        type: "SELECTED_CATEGORY",
                        payload: cate,
                      });
                      history.push("/categories/edit");
                    }}
                    style={{ marginRight: 8 }}
                  /> */}
                    <Button
                      size="small"
                      icon={<DeleteFilled style={{ color: "red" }} />}
                      onClick={() => {
                        mutateDelete(
                          {
                            resource: "products",
                            id: prod._id,
                          },
                          {
                            onSuccess() {
                              refetch();
                            },
                          }
                        );
                      }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
