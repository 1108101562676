import React from "react";
import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Row, Col, Image } from "antd";
import { useSelector } from "react-redux";

export const ShowProduct: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { products } = useSelector((state: any) => ({
    ...state,
  }));
  return (
    <div style={{ margin: 20, padding: 20 }}>
      <Show>
        <div style={{ padding: 50, marginRight: 100 }}>
          <Row gutter={[24, 24]} justify={"start"}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Row gutter={[24, 24]} justify={"end"}>
                <Image
                  src={`http://176.126.87.249/uploads/${products.selectedProduct.image}`}
                  preview={false}
                  height={345}
                  width={180}
                />
              </Row>
            </Col>
            <Col>
              <div style={{ margin: 30 }}>
                <h1>{products.selectedProduct.name}</h1>
                <h4>{products.selectedProduct.description}</h4>
              </div>
            </Col>
          </Row>
        </div>
      </Show>
    </div>
  );
};
